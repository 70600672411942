<mat-toolbar>
  <div class="flex all-wrapper">
    @if (showLogo()) {
      <div class="flex">
        @if (generalStore.logoUrl()) {
          <img class="logo" [ngSrc]="generalStore.logoUrl()!" alt="Logo" height="23" width="157" />
        }
      </div>
    }
    <div class="profile-menu-container">
      <div class="titles">
        <p class="username text-sm">
          {{ currentUser()?.userFullName }}
        </p>
        <p class="desc text-xs">
          {{ currentUser()?.customerName }}
        </p>
      </div>

      <div [matMenuTriggerFor]="menu" class="menu-trigger">
        <app-new-action-button
          class="avatar"
          [icon]="User"
          [themedButton]="'clear-primary'"
          [iconOnlySize]="24"
        ></app-new-action-button>
        <div>
          <mat-menu
            #menu="matMenu"
            xPosition="before"
            class="profile-menu p-2.5 absolute top-6 right-1 mt-[-6px] w-[240px] rounded-xl"
          >
            @if (!currentUser()?.isAzureUser) {
              <app-new-action-button
                [text]="'Change Password'"
                [icon]="Lock"
                [themedButton]="'clear-black'"
                [fullWidth]="true"
                [flexStart]="true"
                (actionCompleted)="openChangePasswordModal()"
              ></app-new-action-button>
            }
            <app-new-action-button
              [text]="'Notification Settings'"
              [icon]="Bell"
              [themedButton]="'clear-black'"
              [fullWidth]="true"
              [flexStart]="true"
              (actionCompleted)="openNotificationSettingsModal()"
            ></app-new-action-button>
            <mat-divider></mat-divider>
            <app-new-action-button
              [text]="'Logout'"
              [icon]="LogOut"
              [themedButton]="'clear-black'"
              [fullWidth]="true"
              [flexStart]="true"
              (actionCompleted)="logout()"
            ></app-new-action-button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</mat-toolbar>
