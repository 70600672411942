import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { Router } from '@angular/router';
import { NewActionButtonComponent } from '../new-action-button/new-action-button.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LucideAngularModule, User, Bell, Lock, LogOut } from 'lucide-angular';
import { DialogService, EmptyPayload } from 'src/services/dialog.service';
import { GeneralStore } from 'src/store/general.store';
import { ChangePasswordDialog } from '../../dialogs/change-password/change-password.dialog';
import { NotificationSettingDialog } from '../../dialogs/notification-setting/notification-setting.dialog';
import { JwtAuthService } from 'src/services/jwt-auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatMenuModule,
    MatToolbarModule,
    MatDialogModule,
    MatDividerModule,
    NgOptimizedImage,
    LucideAngularModule,
    NewActionButtonComponent,
  ],
})
export class HeaderComponent {
  private readonly authService = inject(JwtAuthService);
  private readonly router = inject(Router);
  private readonly dialogService = inject(DialogService);
  protected readonly generalStore = inject(GeneralStore);

  protected readonly User = User;
  protected readonly Lock = Lock;
  protected readonly Bell = Bell;
  protected readonly LogOut = LogOut;
  readonly showLogo = input(false);
  protected currentUser = this.generalStore.userInfo;

  readonly expandSidebar = output();

  protected openChangePasswordModal(): void {
    this.dialogService.open<ChangePasswordDialog, EmptyPayload>({
      payload: {},
      component: ChangePasswordDialog,
      width: '530px',
    });
  }

  protected logout() {
    this.authService.logout(() => {
      this.generalStore.reset();
      this.router.navigate(['/login']);
    });
  }

  protected openNotificationSettingsModal(): void {
    this.dialogService.open<NotificationSettingDialog, EmptyPayload>({
      payload: {},
      component: NotificationSettingDialog,
      width: '608px',
    });
  }
}
